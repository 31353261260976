import { assign, map } from 'lodash'

window.getVisitorData = () => {
  let data = JSON.parse(localStorage.getItem('visitor-cache')) || {}
  if (data.property_size) data.property_size = data.property_size.replace('&lt;', '<').replace('&gt;', '>')

  return data
}

window.clearVisitorData = () => {
  localStorage.removeItem('visitor-cache')
}

window.storeVisitorFields = (fields) => {
  let current = window.getVisitorData()
  current = assign(current, fields)

  if (fields.full_name) [current.first_name, current.last_name] = current.full_name.trim().split(' ')
  else if (fields.first_name || fields.last_name) current.full_name = [current.first_name, current.last_name].join(' ')

  if (fields.company_website) current.website = current.company_website
  else if (fields.website) current.company_website = current.website

  let region = fields.market || fields.region
  if (region) {
    let codes = map(window.Laravel.dictionaries.markets, el => el.value)
    let names = map(window.Laravel.dictionaries.markets, el => el.label)
    let index = codes.indexOf(region)
    if (index < 0) index = names.indexOf(region)

    current.market = current.region = index < 0 ? null : codes[index]
  }

  if ('send_me_tips' in fields) current.send_me_tips = fields.send_me_tips

  localStorage.setItem('visitor-cache', JSON.stringify(current))
}

let prefill = {}
if (window.creditPrefilledData && window.creditPrefilledData.email) {
  prefill = assign(prefill, {
    first_name: window.creditPrefilledData.firstName || null,
    last_name: window.creditPrefilledData.lastName || null,
    bio: window.creditPrefilledData.bio || null,
    email: window.creditPrefilledData.email || null,
    company: window.creditPrefilledData.company || null,
    company_website: window.creditPrefilledData.companyWebsite || null,
    title: window.creditPrefilledData.title || null,
    property_type: window.creditPrefilledData.property_type || null,
    region: window.creditPrefilledData.region || null,
    phone: window.creditPrefilledData.phone || null
  })
}

if (window.leadPrefilledData && window.leadPrefilledData.property_size) {
  prefill = assign(prefill, {
    property_size: window.leadPrefilledData.property_size,
    portfolio_size: window.leadPrefilledData.portfolio_size,
    hotel_type: window.leadPrefilledData.hotel_type,
    property_type: window.leadPrefilledData.property_type,
    hotel_chainscale: window.leadPrefilledData.hotel_chainscale
  })
}
prefill = assign(prefill, window.getVisitorData())
window.storeVisitorFields(prefill)
